import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {ParamsOriginModel} from "../../models/ParamsOriginModel";
import {ContactForm, LabelContact} from "../../components/contacto-form/ContactForm";

const Contacto = () => {

    const params = useParams<ParamsOriginModel>();

    useEffect(() => {
    }, [params.origin]);

    const createContactForm = (id: number): void =>
        console.log('id de contacto ' + id)
    return (
        <>
            <div className="col-md-8 offset-md-2 pt-4">
                <ContactForm
                    idContact={-1}
                    originQueryParam={params.origin!}
                    labelForm={LabelContact.contact}
                    createContactForm={createContactForm}
                    idProduct={0}

                />
            </div>


        </>


    );
};

export default Contacto;
