import axios from "axios";
import environment from "../../environments/environment";
import { UserModel } from "./types";

export const getSigninUrb = async (urb: string) => {
  const api = environment.API_SESSION;
  const apiKey = environment.API_SESSION_KEY;
  const env = environment.env;
  return await axios.get<UserModel>(api + "/signinurb?urb=" + urb, {
    headers: {
      Accept: "application/json",
      "X-API-KEY": apiKey,
      env,
    },
  });
};

export function getProfile(): UserModel {
  return JSON.parse(localStorage.getItem("PERFIL") || "{}");
}

export const initPerfil: UserModel = {
  id: "",
  username: "",
  email: "",
  roles: [],
  accessToken: "",
  tokenType: "",
  clave: "",
};
