import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import './assets/css/fonts.scss';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-input-2/lib/style.css'
import Contacto from "./views/contacto/Contacto";
import Cotizador from "./views/cotizador/Cotizador";
import bolsa from "./views/bolsa-agente/ContactoBolsa";
import agente from "./views/bolsa-agente/ContactoAgente";
import NotFound from "./views/not-found/NotFound";
import UserPoliciesSubmission from './views/UserPoliciesSubmission';
import { SerfLogin } from './components/SerfLogin';

ReactDOM.render(
  <React.StrictMode>
      
      <BrowserRouter basename={"/"}>
          <Switch >
              <Route exact path="/contacto/:origin" component={withRouter(Contacto)} />
              <Route exact path="/user-policies-submission" component={UserPoliciesSubmission} />
              <Route exact path="/cotizador/:origin" component={withRouter(Cotizador)} />
              <Route exact path="/bolsa-trabajo/:origin" component={withRouter(bolsa)} />
              <Route exact path="/contacto-agente/:origin" component={withRouter(agente)} />
              <Route exact path="/serf-login/:urb/:key/:origin" component={SerfLogin} />
              <Route exact path="/404/" component={NotFound} />
              <Route component={NotFound} />
          </Switch>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
