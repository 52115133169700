import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getSigninUrb } from "./utils";
import logo from "../../assets/img/logo-web-liberty16.png";
import { AxiosError } from "axios";
import { Alert, Container, Spinner } from "react-bootstrap";

export const SerfLogin: React.FC = () => {
  const { urb, key, origin } = useParams<Partial<IParams>>();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const navigate = useCallback((path: string) => history.push(path), [history]);

  useEffect(() => {
    const signIn = async () => {
      try {
        setIsLoading(true);
        if (!urb || !key || !origin)
          throw new Error("Parámetros no válidos" || "");
        const res = await getSigninUrb(urb);
        const user = res.data;
        user.clave = key;
        localStorage.setItem("PERFIL", JSON.stringify(user));
        navigate(`/${origin}`);
      } catch (error) {
        const e = error as AxiosError;
        setErrorMessage(e.message || "");
      } finally {
        setIsLoading(false);
      }
    };

    signIn();
  }, [key, navigate, origin, urb]);

  return (
    <Container>
      <img
        src={logo}
        alt="Liberty Fianzas logo"
        className="img-fluid d-block mx-auto mt-3"
      />
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center text-center flex-column">
          <Spinner animation="border" role="status" />
          <span>Cargando...</span>
        </div>
      )}

      {errorMessage && (
        <Alert variant="danger" className="mt-3">
          Algo salió mal. Por favor, inténtelo de nuevo más tarde. <br />
          Código de error: {errorMessage}
        </Alert>
      )}
    </Container>
  );
};

interface IParams {
  urb: string;
  key: string;
  origin: string;
}
