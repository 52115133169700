import React, { ChangeEvent, FormEvent, useEffect, useState, useRef } from "react";
import './Cotizador.scss';
import CurrencyInput from 'react-currency-input-field';
import {EntidadModel} from "../../models/EntidadModel";
import * as contactoService from "../../services/contacto/ContactoService";
import {QuoteModel} from "../../models/QuoteModel";
import {MonedaModel} from "../../models/MonedaModel";
import {Button, Modal} from "react-bootstrap";
import {ContactForm, LabelContact} from "../../components/contacto-form/ContactForm";
import {useParams} from "react-router-dom";
import {ParamsOriginModel} from "../../models/ParamsOriginModel";
import {SelectProduct} from "../../components/select-products/SelectProduct";
import {AxiosError} from "axios";
// @ts-ignore
import PDF from "../../assets/files/AVISO-DE-PRIVACIDAD.pdf";
type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;


const Cotizador = () => {

    const params = useParams<ParamsOriginModel>();

    const initialStateQuote: QuoteModel = {
        idContacto: 0,
        idMoneda:  0,
        monto:  0,
        fechaInicial: '',
        fechaFinal: ''
    };

    const [quote, setQuote] = useState<QuoteModel>(initialStateQuote);

    const [entidades, setEntidades] = useState<EntidadModel []>([]);

    const [monedas, setMonedas] = useState<MonedaModel []>([]);

    useEffect(() => {
        getMonedas();
    },[params.origin]);

    const getMonedas = async () => {
        const res = await contactoService.getMonedas();
        setMonedas(res.data)
        setLoadMoneda(true);
    };

    const [loadMoneda, setLoadMoneda] = useState(false);

    const [showContact, setShowContact] = useState(false);

    const [showModalSucces, setShowModalSucces] = useState(false);

    const handleCloseModalSucces = () => setShowModalSucces(false);

    const handleShowModalSuccess = () => setShowModalSucces(true);

    const [sending, setSending] = useState(false);

    const [showErrorSubmit, setShowErrorSubmit] = useState<boolean>(false);

    const handleSubmitQuote = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateFormQuote()) {
            setShowContact(true);
        }

    };

    // validaciones cotizador
    const [validMoneda, setValidMoneda] = useState<string>('');
    const [validMonto, setValidMonto] = useState<string>('');
    const [validFechaIni, setValidFechaIni] = useState<string>('');
    const [validFechaFin, setValidFechaFin] = useState<string>('');

    const validateFormQuote = () : boolean => {

        setValidMoneda('');
        setValidMonto('');
        setValidFechaIni('');
        setValidFechaFin('');

     /*   if (!quote.moneda){
            setValidMoneda('Ingresa por favor algún tipo de Moneda, por favor.')
            return false;
        }

        if (quote.monto <= 0){
            setValidMonto('Ingresa por favor un monto válido, por favor.')
            return false;
        }*/
        if (!quote.fechaInicial){
            setValidFechaIni('Selecciona por favor una fecha Inicial, por favor.')
            return false;
        }

        if (!quote.fechaFinal){
            setValidFechaFin('Selecciona por favor una fecha Final, por favor.')
            return false;
        }


        return true;
    };

    const handleInputChangeQuote = (e: InputChange) => {

        setQuote({ ...quote, [e.target.name]: e.target.value });
        // si es el ramo buscar el tipo por medio de ese id
       if (e.target.name == 'fechaInicial'){

            const dateIni =  e.target.value;
           // suma un año
            const newYear = sumDate(dateIni, 365);
            setQuote({ ...quote, fechaFinal:newYear , [e.target.name]: e.target.value});
        } else {

            setQuote({ ...quote, [e.target.name]: e.target.value });
        }


    };


    // contacto
    const createContactForm = async (idContact: number) => {
        //alert('id de contacto ' + id)

         let auxQuote: QuoteModel = quote;
         auxQuote.idContacto = idContact;

        if (validateFormQuote()) {
            setSending(true);
            try {
                const res = await contactoService.createNewQuote(auxQuote);
                setQuote(initialStateQuote);

                setSending(false);
            } catch (error) {
                const err = error as AxiosError
                setSending(false);

                if (err.response) {
                    console.log(err.response.status)
                    console.log(err.response.data)
                }
            }
        }
    }


    // producto
    const [selectProductId, setSelectProductId] = useState<number>(0);

    const selectedProduct = (id: number): void => {
        setSelectProductId(id);
        //alert('product id ' + id)
    }

    const sumDate = (date: string, nDays: number) => {

        let result = new Date(date);
        result.setDate(result.getDate() + nDays);
        const days = (result.getDate()<10) ? '0' + result.getDate() : result.getDate();
        const month = ((result.getMonth()+1) <10) ? '0'+ (result.getMonth()+1) : (result.getMonth()+1);
        // le sumamos NDays días y lo seteamos en fecha final

        return  result.getFullYear()+ '-' + month + '-' + days

    }

    const subLabelFom = "Por favor compartenos tus datos para enviarte la cotización por correo";

    return (
        <div className="col-md-8 offset-md-2 pt-4" id="cotizador">

            { !showContact ? (

                <div className="" id="contact-quote">
                    <h1>Solicitar Cotización</h1>
                    <h4>{subLabelFom}</h4>
                    <div className="row">
                        <div className="col-12 politica-priv">
                            Los datos personales que aquí se recaban por Liberty Fianzas, S.A de C.V.
                            serán utilizados con la finalidad de ponernos en contacto con usted,
                            atenderle y aclarar cualquier duda respecto a las fianzas que ofrecemos.
                            <br/>
                            Si desea conocer nuestro aviso de privacidad, de clic <a href={PDF} target="_blank">aquí</a>.
                            <br/><br/>
                        </div>

                    </div>
                    <form onSubmit={handleSubmitQuote} className="form-row align-items-center">

                        <div className="col-sm-12 my-1">
                            <SelectProduct selectedProduct={selectedProduct} />
                        </div>

                        <div className="col-sm-12 my-1">
                            <label htmlFor="moneda">Moneda:</label>
                            <span className="float-right text-muted">Opcional</span>
                            <select
                                className="form-control"
                                name="idMoneda"
                                id="moneda"
                                onChange={handleInputChangeQuote}
                                value={quote.idMoneda}>
                                <option value='0'>Selecciona una Moneda</option>
                                {monedas.map((iMoneda) => (
                                    <option value={iMoneda.idMoneda} key={iMoneda.idMoneda}>{iMoneda.descripcion}</option>
                                ))}
                            </select>

                            {validMoneda.length>0 ? (
                                <div className="invalid-feedback">
                                    {validMoneda}
                                </div>
                            ): null}
                        </div>

                        <div className="col-sm-12 my-1">
                            <label htmlFor="empresa">Monto:</label>
                            <span className="float-right text-muted">Opcional</span>
                            <CurrencyInput
                                id="input-example"
                                className="form-control"
                                name="monto"
                                prefix="$"
                                placeholder="Ingresa un Monto por favor."
                                defaultValue={0}
                                decimalsLimit={2}
                                onValueChange={(value, name) => {
                                    setQuote({ ...quote, [name!]: value! });

                                } }
                            />

                            {validMonto.length>0 ? (
                                <div className="invalid-feedback">
                                    {validMonto}
                                </div>
                            ): null}

                        </div>

                        <div className="col-sm-6 my-1">
                            <label htmlFor="fechaInicial">Fecha Inicial:</label>
                            <input
                                type="date"
                                name="fechaInicial"
                                className={"form-control"}
                                id="fechaInicial"
                                onChange={handleInputChangeQuote}
                                value={quote.fechaInicial}
                            />

                            {validFechaIni.length>0 ? (
                                <div className="invalid-feedback">
                                    {validFechaIni}
                                </div>
                            ): null}

                        </div>

                        <div className="col-sm-6 my-1">
                            <label htmlFor="fechaFinal">Fecha Final:</label>
                            <input
                                type="date"
                                name="fechaFinal"
                                className={"form-control"}
                                id="fechaFinal"
                                onChange={handleInputChangeQuote}
                                value={quote.fechaFinal}
                            />

                            {validFechaFin.length>0 ? (
                                <div className="invalid-feedback">
                                    {validFechaFin}
                                </div>
                            ): null}

                        </div>


                        <div className="col-sm-12 my-1  mt-4">
                            <button
                                className="btn btn-send"
                                onClick={()=>{
                                    if (validateFormQuote()){
                                        setShowContact(true)
                                    }
                                }}>
                                &nbsp;&nbsp;Siguiente&nbsp;&nbsp;
                            </button>
                        </div>

                    </form>
                </div>


            ):

                 <div className="" id="contact-form">

                     <ContactForm
                         idContact={null}
                         originQueryParam={params.origin!}
                         subLabelFom={subLabelFom}
                         labelForm={LabelContact.quote}
                         createContactForm={createContactForm}
                         idProduct={selectProductId}

                     />

                       {/*  {showErrorSubmit ? (
                             <Alert variant="warning" onClose={() => setShowErrorSubmit(false)} dismissible>
                                 <Alert.Heading className={"alert-heading"}>No se pudo enviar </Alert.Heading>
                                 <p className={"msg-heading"}>
                                     Lo sentimos, algo ha ocurrido al enviar, intenta nuevamente.
                                 </p>
                             </Alert>
                         ): null}*/}




                    </div>
            }

            <Modal show={showModalSucces} onHide={handleCloseModalSucces}>
                <Modal.Header closeButton>
                    <Modal.Title className="modal-title">Aviso</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-text">
                    Su mensaje ha sido enviado, uno de nuestros ejecutivos en breve lo contactará.
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'btn btn-modal'} variant="primary" onClick={handleCloseModalSucces}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>);
};

export default Cotizador;
