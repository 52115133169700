import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import "./ContactBolsaAgenteForm.scss";
import PhoneInput from "react-phone-input-2";
import { bolsaAgenteModel } from "../../models/bolsaAgenteModel";
import { Button, Modal, Alert, Card, ListGroup, Form } from "react-bootstrap";
import * as contactoService from "../../services/contacto/ContactoService";
import * as validaciones from "../../class/Validaciones";
import ReCAPTCHA from "react-google-recaptcha";
import { AxiosError } from "axios";
import { useHistory } from "react-router-dom";
import { OriginModel } from "../../models/OriginModel";
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner";

// @ts-ignore
import PDF from "../../assets/files/AVISO-DE-PRIVACIDAD.pdf";
import Dropzone from "./Dropzone";

type InputChange = ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
>;

interface PropsContactoForm {
  //   idContact: number | null;
  originQueryParam: string;

  labelForm: LabelContact;
  subLabelFom?: string;
  createContactBolsaForm: (id: number) => any;
  idProduct?: number;
  cv?: boolean;
}

export const ContactBolsaAgenteForm = ({
  //   idContact,
  originQueryParam,
  cv,
  createContactBolsaForm,
  labelForm,
  subLabelFom,
}: //   idProduct,
PropsContactoForm): JSX.Element => {
  const history = useHistory();

  const initialState: bolsaAgenteModel = {
    nombre: "",
    apellidos: "",
    correo: "",
    telefono: "52",
    mensaje: "",
    ciudad: "",
    cv: "",
  };

  const [loadListOrigin, setLoadListOrigin] = useState(false);

  const [contact, setContact] = useState<bolsaAgenteModel>(initialState);

  const [captchaValid, setCaptchaValid] = useState(false);

  const captcha = useRef(null);

  useEffect(() => {
    if (originQueryParam) {
      // traer la lista de origenes
      // y buscar si esta dentro de ellos
      const getOrigins = async () => {
        setLoadListOrigin(true);
        try {
          const res = await contactoService.getOrigins();
          const listOrigins: OriginModel[] = res.data;
          console.log(listOrigins);
          let foundOrigin = false;
          for (let i of listOrigins) {
            if (i.descripcion == originQueryParam) {
              foundOrigin = true;
              // guardar el origen
              setContact({
                ...contact,
              });
              setLoadListOrigin(true);

              break;
            }
          }
          if (!foundOrigin) {
            history.push("/404/");
          }
        } catch (error) {
          const err = error as AxiosError;
          if (err.response) {
            console.log(err.response.status);
            console.log(err.response.data);
            history.push("/404/");
          }
        }
      };

      getOrigins();
    }
  }, []);

  const handleInputChange = (e: InputChange) => {
    console.log("contact: ", contact);
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const [showModalSucces, setShowModalSucces] = useState(false);
  const handleCloseModalSucces = () => setShowModalSucces(false);
  const handleShowModalSuccess = () => setShowModalSucces(true);
  const [sending, setSending] = useState(false);
  const [showErrorSubmit, setShowErrorSubmit] = useState<boolean>(false);

  // validaciones
  const [validName, setValidName] = useState<string>("");
  const [validCiudad, setValidCiudad] = useState<string>("");
  const [validApellidos, setValidApellidos] = useState<string>("");
  const [validCorreo, setValidCorreo] = useState<string>("");
  const [validTelefono, setValidTelefono] = useState<string>("");
  const [validMsg, setValidMsg] = useState<string>("");
  const [validCaptcha, setValidCaptcha] = useState<string>("");
  const MAXSIZEUPLOADFILE = 52428800;
  const [excedLimitFile, setExcedLimitFile] = useState<boolean>(false);

  const nowDate = (): string => {
    const d = new Date();
    return d.toISOString();
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async function () {
        resolve(reader.result?.toString().split(",")[1] as string);
      };
    });
  };

  const handleFileDrop = async (files: File[]) => {
    const file = files[0];
    if (!file) return;
    if (file.size >= MAXSIZEUPLOADFILE) {
      setExcedLimitFile(true);
      return;
    } else {
      const formData = new FormData();
      formData.append("nombre", "");
      formData.append("fileName", file);
      formData.append("fechaCarga", nowDate());
      const cv = await getBase64(file);
      setContact({ ...contact, cv });
      console.log("contact: ", contact);
    }
  };

  const validateFormContact = (): boolean => {
    setValidName("");
    setValidApellidos("");
    setValidCorreo("");
    setValidTelefono("");
    setValidMsg("");
    setValidCaptcha("");

    if (!contact.nombre) {
      setValidName("Ingresa por favor un nombre.");
      return false;
    }
    if (!contact.ciudad) {
      setValidCiudad("Ingresa por favor alguna ciudad");
      return false;
    }
    if (!contact.apellidos) {
      setValidApellidos("Ingresa por favor almenos un apellido.");
      return false;
    }
    if (!validaciones.validateEmail(contact.correo)) {
      setValidCorreo("Ingresa por favor una dirección de correo válida.");
      return false;
    }
    if (!contact.telefono || contact.telefono.length < 9) {
      console.log("contact.telefono: ", contact.telefono);
      // console.log("contact.telefono tamaño: ",contact.telefono.length)
      setValidTelefono("Ingresa un teléfono válido, por favor.");
      return false;
    }
    if (!contact.mensaje) {
      setValidMsg("Ingresa un Mensaje válido, por favor.");
      return false;
    }
    if (!captchaValid) {
      setValidCaptcha("Haz clic en Yo no soy un Robot, por favor.");
      return false;
    }

    return true;
  };
  //captcha
  const onChange = (value: any) => {
    //console.log("Captcha value:", value);
    // @ts-ignore
    if (captcha.current.getValue()) {
      // @ts-ignore
      console.log(captcha.current.getValue());
      setCaptchaValid(true);
    }
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateFormContact()) {
      setSending(true);
      console.log("contacto2");

      try {
        let res;
        // const res = await contactoService.createNewBolsaModel(contact);

        if (cv === false) {
          res = await contactoService.createNewBolsaModel(contact);
        } else {
          res = await contactoService.createNewAgenteModel(contact);
        }

        setContact(initialState);
        handleShowModalSuccess();
        setSending(false);
        const contactForm: bolsaAgenteModel = res.data;
        console.log("contactForm--->:  ", contactForm);
        // createContactBolsaForm(contactForm!);
      } catch (error) {
        const err = error as AxiosError;
        setSending(false);
        setShowErrorSubmit(true);
        if (err.response) {
          console.log(err.response.status);
          console.log(err.response.data);
        }
      }
    }
  };
  return (
    <>
      {loadListOrigin ? (
        <div id="contact-form">
          <h1>{labelForm}</h1>
          <h4>{subLabelFom}</h4>
          {/*{ JSON.stringify(contact) }*/}
          {showErrorSubmit ? (
            <Alert
              variant="warning"
              onClose={() => setShowErrorSubmit(false)}
              dismissible
            >
              <Alert.Heading className={"alert-heading"}>
                No se pudo enviar{" "}
              </Alert.Heading>
              <p className={"msg-heading"}>
                Lo sentimos, algo ha ocurrido al enviar, intenta nuevamente.
              </p>
            </Alert>
          ) : null}
          <div className="row">
            <div className="col-12 politica-priv">
              Solicitud
              <br />
            </div>
          </div>
          <form onSubmit={handleSubmit} className="form-row align-items-center">
            <div className="col-sm-6 my-1">
              <label htmlFor="nombre">Nombre:</label>
              <input
                type="text"
                name="nombre"
                className={
                  "form-control" + (validName.length > 0 ? " is-invalid" : "")
                }
                id="nombre"
                autoFocus
                onChange={handleInputChange}
                value={contact.nombre}
              />

              {validName.length > 0 ? (
                <div className="invalid-feedback">{validName}</div>
              ) : null}
            </div>
            <div className="col-sm-6 my-1">
              <label htmlFor="apellidos">Apellidos:</label>
              <input
                type="text"
                name="apellidos"
                className={
                  "form-control" +
                  (validApellidos.length > 0 ? " is-invalid" : "")
                }
                id="apellidos"
                onChange={handleInputChange}
                value={contact.apellidos}
              />

              {validApellidos.length > 0 ? (
                <div className="invalid-feedback">{validApellidos}</div>
              ) : null}
            </div>
            <div className="col-sm-6 my-1">
              <label htmlFor="correo">E-mail:</label>
              <input
                type="email"
                name="correo"
                className={
                  "form-control" + (validCorreo.length > 0 ? " is-invalid" : "")
                }
                id="correo"
                onChange={handleInputChange}
                value={contact.correo}
              />
              {validCorreo.length > 0 ? (
                <div className="invalid-feedback">{validCorreo}</div>
              ) : null}
            </div>
            <div className="col-sm-6 my-1">
              <label htmlFor="telefono">Teléfono:</label>
              <PhoneInput
                country={"mx"}
                value={contact.telefono}
                onChange={(phone) => {
                  const a: bolsaAgenteModel = { ...contact, telefono: phone };
                  setContact(a);
                }}
              />
              {validTelefono.length > 0 ? (
                <div className="invalid-feedback">{validTelefono}</div>
              ) : null}
            </div>

            <div className="col-sm-12 my-1">
              <label htmlFor="ciudad">Ciudad:</label>
              <input
                type="text"
                name="ciudad"
                className={
                  "form-control" + (validCiudad.length > 0 ? " is-invalid" : "")
                }
                id="ciudad"
                autoFocus
                onChange={handleInputChange}
                value={contact.ciudad}
              />

              {validCiudad.length > 0 ? (
                <div className="invalid-feedback">{validCiudad}</div>
              ) : null}
            </div>

            <div className="col-sm-12 my-1">
              <label htmlFor="nombre">
                ¿Por qué quieres ser parte de nuestro equipo?:
              </label>
              <textarea
                className="form-control"
                name="mensaje"
                id="mensaje"
                onChange={handleInputChange}
                value={contact.mensaje}
                rows={3}
              ></textarea>

              {validMsg.length > 0 ? (
                <div className="invalid-feedback">{validMsg}</div>
              ) : null}
            </div>
            {/* SUBIR ARCHIVO */}

            <div className="col-12 mt-3" hidden={cv}>
              <Dropzone 
                accept={{
                  "application/pdf": [".pdf"],
                }}
                maxFiles={1}
                onDrop={handleFileDrop}
              />
            </div>

            <div className="col-sm-12 my-1" id="recaptcha">
              <ReCAPTCHA
                ref={captcha}
                sitekey="6LeV__cbAAAAADjQxYptRqGsjfCzZQy85G1-CnNf"
                onChange={onChange}
              />

              {validCaptcha.length > 0 ? (
                <div className="invalid-feedback">{validCaptcha}</div>
              ) : null}
            </div>

            <div className="col-sm-12  mt-4 ">
              {!sending ? (
                <button className="btn btn-send">
                  &nbsp;&nbsp; Enviar &nbsp;&nbsp;
                </button>
              ) : (
                <button className="btn btn-send" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  &nbsp;&nbsp;Enviando
                </button>
              )}
            </div>
          </form>

          <Modal show={showModalSucces} onHide={handleCloseModalSucces}>
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">Aviso</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-text">
              Su mensaje ha sido enviado, uno de nuestros ejecutivos en breve lo
              contactará.
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={"btn btn-modal"}
                variant="primary"
                onClick={handleCloseModalSucces}
              >
                Aceptar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <div className="col-md-8 offset-md-2 pt-4" id="contact-form">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};
export enum LabelContact {
  bolsa = "Bolsa Trabajo",
  agente = "Quiero Ser Agente",
}
