import React, { FormEvent, useEffect, useState } from "react";
import { IPolicyFormData } from "./types";
import { AxiosError } from "axios";
import { getPolicy, savePolicy } from "./utils";
import { usePoliciesProfiles } from "../../hooks/usePoliciesProfiles";
import "@lmig/lmds-react/base.css";
import {
  Alert,
  AlphanumericInput,
  Button,
  Content,
  Form,
  GridCol,
  GridRow,
  Select,
  SelectOption,
} from "@lmig/lmds-react";

const initialData = {
  profile: 0,
  title: "",
  manifest: "",
};
const UserPoliciesSubmission: React.FC = () => {
  const [data, setData] = useState<IPolicyFormData>(initialData);
  const fileInput = React.useRef<HTMLInputElement>(null);

  const { profiles, isLoading, error } = usePoliciesProfiles();
  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState<AxiosError>();
  const [success, setSuccess] = useState(false);
  const [prevFileUrl, setPrevFileUrl] = useState("");

  useEffect(() => {
    const getPreviousData = async () => {
      const response = await getPolicy(data.profile);
      setData({
        title: response.titulo,
        manifest: response.manifiesto,
        profile: data.profile,
      });
      setPrevFileUrl(response.url);
      if (fileInput.current) fileInput.current.value = "";
    };
    if (data.profile) {
      getPreviousData();
    }
  }, [data.profile]);

  const getErrorMsg = () => {
    if (error) return error.message;

    if (savingError) return savingError.message;

    return "";
  };
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSuccess(false);
    setSavingError(undefined);

    try {
      setSaving(true);
      await savePolicy(data);
      setSuccess(true);
      setData(initialData);
    } catch (error) {
      const e = error as AxiosError;
      setSavingError(e);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Content className="p-4">
      <Form onSubmit={handleSubmit}>
        <h1 className="mb-3">Registro de politicas para usuarios</h1>
        {success && (
          <Alert highlightType="positive">Política creada correctamente</Alert>
        )}
        {getErrorMsg() && (
          <Alert highlightType="negative">
            Algo salió mal, por favor inténtelo de nuevo más tarde.
            <br />
            Código de error: {getErrorMsg()}
          </Alert>
        )}

        <Select
          labelVisual="Perfil"
          isRequired
          value={data.profile.toString()}
          onChange={(e) =>
            setData({ ...data, profile: Number(e.currentTarget.value) })
          }
        >
          <SelectOption value="0" disabled>
            {isLoading ? "Cargando perfiles..." : "Seleccione un perfil"}
          </SelectOption>
          {profiles.map((profile) => (
            <SelectOption
              key={profile.idPerfil}
              value={profile.idPerfil.toString()}
            >
              {profile.descripcion}
            </SelectOption>
          ))}
        </Select>

        <AlphanumericInput
          labelVisual="Título del documento"
          required
          value={data.title}
          onChange={(e) => setData({ ...data, title: e.target.value })}
        />

        <AlphanumericInput
          labelVisual="Manifiesto"
          required
          value={data.manifest}
          onChange={(e) => setData({ ...data, manifest: e.target.value })}
        />

        <GridRow>
          <GridCol>
            <label className="d-block">Archivo</label>
            <input
              type="file"
              required
              accept="application/pdf"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setData({ ...data, file: e.target.files?.[0] })
              }
              ref={fileInput}
            />
          </GridCol>
          <GridCol className="d-flex justify-content-center align-items-center">
            <Button
              style={{ maxWidth: "none", width: "100%" }}
              className="mt-3 mt-md-0"
              disabled={!data.file && !prevFileUrl}
              onClick={() => {
                // If there is a selected file, open it in a new tab
                if (data.file) window.open(URL.createObjectURL(data.file));
                // Otherwise, open the previous file in a new tab
                else if (prevFileUrl) window.open(prevFileUrl, "_blank");
              }}
            >
              Ver archivo seleccionado
            </Button>
          </GridCol>
        </GridRow>

        <Button
          type="submit"
          disabled={saving}
          style={{ display: "block", width: "100%", maxWidth: "none" }}
          variant="primary"
          className="mt-3"
        >
          {saving ? "Guardando..." : "Registrar"}
        </Button>
      </Form>
    </Content>
  );
};

export default UserPoliciesSubmission;
