import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {ParamsOriginModel} from "../../models/ParamsOriginModel";
import {ContactBolsaAgenteForm, LabelContact} from "../../components/contacto-bolsa-agente/ContactBolsaAgenteForm";

const ContactoBolsa = () => {

    const params = useParams<ParamsOriginModel>();

    useEffect(() => {
    }, [params.origin]);

    const createContactForm = (id: number): void =>
        console.log('id de contacto ' + id)
    return (
        <>
            <div className="col-md-8 offset-md-2 pt-4">
                <ContactBolsaAgenteForm
                    // idContact={-1}
                    originQueryParam={params.origin!}
                    labelForm={LabelContact.bolsa}
                    cv={false}
                    createContactBolsaForm={createContactForm}
                    // idProduct={0}
                />
            </div>
        </>


    );
};

export default ContactoBolsa;
