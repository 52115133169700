import axios from "axios";
import {ContactModel} from "../../models/contactModel";
import {EntidadModel} from "../../models/EntidadModel";
import {RamoModel} from "../../models/RamoModel";
import {MonedaModel} from "../../models/MonedaModel";
import {TipoModel} from "../../models/TipoModel";
import {QuoteModel} from "../../models/QuoteModel";
import {OriginModel} from "../../models/OriginModel";
import {ProductsModel} from "../../models/ProductsModel";
import environment from "../../environments/environment";
import { bolsaAgenteModel } from "../../models/bolsaAgenteModel";

const API = environment.APICONTACTO

const  headers = { Accept: 'application/json',  'Content-Type' : 'application/json',  'API_KEY':'--Xt69Lbcb1DNgbhO_Q6LA..' }

export const getEntidades = async () => {
    return await axios.get<EntidadModel[]>(`${API}/entidades` , { headers: headers });
};

export const getRamos = async () => {
    return await axios.get<RamoModel[]>(`${API}/ramos` , { headers: headers });
};

export const getMonedas = async () => {
    return await axios.get<MonedaModel[]>(`${API}/monedas` , { headers: headers });
};
export const getTipos = async (idTipo: number) => {
    return await axios.get<TipoModel[]>(`${API}/tipos/` + idTipo  , { headers: headers });
};

export const getOrigins = async () => {
    return await axios.get<OriginModel[]>(`${API}/origen`, { headers: headers });
};

export const getProducts = async (busqueda: string) => {
    return await axios.get<ProductsModel[]>(`${API}/productos/` + busqueda, { headers: headers });
};

export const createNewContact = async (contacto : ContactModel) => {
    return await axios.post<ContactModel>(`${API}/contacto`, contacto,{ headers: headers } );
};
export const createNewBolsaModel = async (contacto : bolsaAgenteModel) => {
    return await axios.post<bolsaAgenteModel>(`${API}/bolsadetrabajo`, contacto,{ headers: headers } );
};
export const createNewAgenteModel = async (contacto : bolsaAgenteModel) => {
    return await axios.post<bolsaAgenteModel>(`${API}/quieroseragente`, contacto,{ headers: headers } );
};
export const createNewQuote = async (quote : QuoteModel) => {
    return await axios.post<QuoteModel>(`${API}/cotizador`, quote,{ headers: headers } );
    //?origin=1
};

/*export const getVideoById = async (id: string) => {
    return await axios.get<Video>(`${API}/videos/${id}`);
};

export const deleteVideoById = async (id: string) => {
    return await axios.delete(`${API}/videos/${id}`);
};

export const updateVideo = async (id: string, video: Video) => {
    return await axios.put(`${API}/videos/${id}`, video);
};*/
