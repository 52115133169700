

export const validateEmail =  (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

//dd/mm/yyyy
export const reformatDate = (isoDate: string): string => {
    const date = new Date(isoDate);
    // tslint:disable-next-line:max-line-length
    return date.getDate() + '/ ' + (date.getMonth() + 1 ) + '/ ' + date.getFullYear()    ;
}
