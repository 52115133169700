import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from "react";
import './SelectProduct.scss';

import {Card, ListGroup} from 'react-bootstrap';
import * as contactoService from '../../services/contacto/ContactoService';

import {ProductsModel} from "../../models/ProductsModel";

type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;

interface PropsSelectProduct {
    selectedProduct: (id: number) => any;
}

export const SelectProduct = ({selectedProduct}: PropsSelectProduct): JSX.Element  => {

    const [selectProductId, setSelectProductId] = useState<number>(0);

    const [productName, setProductName] = useState<string>('');

    const [products, setProducts] = useState<ProductsModel []>([]);

    useEffect(() => {
    }, []);

    const handleInputChange = (e: InputChange) =>{

        if (e.target.name == 'idTipo'){
            setProductName(e.target.value);
            searchProducts(e.target.value);
        }
    }

    const searchProducts = async (productsName: string) => {
        if (productsName.length>1){
            const res = await contactoService.getProducts(productsName);
            console.log(res);
            const  listProducts: ProductsModel [] = res.data
            setProducts(listProducts)
            // si tenemos algo seleccionado
            let coincideNombre= false;
            if (selectProductId){
                for (let i  of listProducts){
                    if (i.descripcion== productsName){
                        coincideNombre= true;
                    }
                }
            }

            if (!coincideNombre){
                setSelectProductId(0);
            }
        }
    }

    const selectProduct = (product: ProductsModel) => {
        //setContact({ ...contact, idTipo:  });
        setSelectProductId(product.idTipo);
        setProductName(product.descripcion)
        selectedProduct(product.idTipo);

        let foundCoincidenece = false;
        for (let i of products){
            if (i.descripcion == productName){
                foundCoincidenece= true;
                break;
            }
        }
        if (!foundCoincidenece){
            setProducts([])
        }

    }

    // validaciones
    const [validProduct, setValidProduct] = useState<string>('');

    const validateProduct = () : boolean => {
        setValidProduct('');

        if (!selectProductId){
            setValidProduct('Ingresa un producto válido, por favor.');
            return false;
        }

        return true;
    };

    return (
        <>
                <label htmlFor="products">Producto:</label>
                {validProduct.length>0 ? (
                    <div className="invalid-feedback">
                        {validProduct}
                    </div>
                ): null}
                <input
                    type="text"
                    name="idTipo"
                    className={"form-control"+  (validProduct.length>0 ? ' is-invalid' : '')}
                    id="correo"
                    autoComplete="off"
                    onChange={handleInputChange}
                    value={productName}/>
                {products.length > 0 ? (
                    <Card className="list-products">
                        <Card.Header>Selecciona un producto: </Card.Header>
                        <ListGroup
                            variant="flush"
                        >
                            {products.map((i) => (
                                <ListGroup.Item
                                    key={i.idTipo}
                                    onClick={() => {
                                        selectProduct(i)
                                    }}
                                >
                                    {i.descripcion}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Card>
                ):null
                }
        </>
    );
};


export enum LabelContact {
    contact = 'Contacto',
    quote = 'Solicitar Cotización'
}

