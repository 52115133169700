import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from "react";
import './ContactForm.scss';
import PhoneInput from 'react-phone-input-2'
import {ContactModel} from "../../models/contactModel";
import {Button, Modal, Alert, Card, ListGroup, Form} from 'react-bootstrap';
import * as contactoService from '../../services/contacto/ContactoService';
import {EntidadModel} from "../../models/EntidadModel";
import * as validaciones from "../../class/Validaciones";
import ReCAPTCHA from "react-google-recaptcha";
import {AxiosError} from "axios";
import { useHistory } from "react-router-dom";
import {OriginModel} from "../../models/OriginModel";
import {ProductsModel} from "../../models/ProductsModel";
import {LoadingSpinner} from "../../components/loading-spinner/LoadingSpinner";

// @ts-ignore
import PDF from '../../assets/files/AVISO-DE-PRIVACIDAD.pdf';

type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;

interface PropsContactoForm {
    idContact: number| null;
    originQueryParam: string;
    labelForm: LabelContact;
    subLabelFom?: string;
    createContactForm: (id: number) => any;
    idProduct?: number;
}

export const ContactForm = ({idContact, originQueryParam , createContactForm, labelForm, subLabelFom,idProduct}: PropsContactoForm): JSX.Element  => {

    const history = useHistory();

    const initialState: ContactModel = {
        idContacto: -1,
        nombre: '',
        empresa: '',
        correo: '',
        telefono: '52',
        entidadFederativa: '',
        mensaje: '',
        idTipo: 0,
        idOrigen:0
    };

    const [loadListOrigin, setLoadListOrigin] = useState(false);

    const [contact, setContact] = useState<ContactModel>(initialState);

    const [entidades, setEntidades] = useState<EntidadModel []>([]);

    const [loadEntidades, setLoadEntidades] = useState(false);

    const [productName, setProductName] = useState<string>('');

    const [products, setProducts] = useState<ProductsModel []>([]);



    const [captchaValid, setCaptchaValid] = useState(false);

    const captcha = useRef(null);

    useEffect(() => {
        if (originQueryParam) {
            // traer la lista de origenes
            // y buscar si esta dentro de ellos
            const getOrigins = async () => {
                setLoadListOrigin(true);
                try {
                    const res = await contactoService.getOrigins();
                    const listOrigins: OriginModel[] = res.data;
                    console.log(listOrigins);
                    let foundOrigin = false;
                    for (let i of listOrigins){
                        if (i.descripcion == originQueryParam){
                            foundOrigin= true;
                            // guardar el origen
                            setContact({ ...contact, idContacto: idContact, idOrigen: i.idOrigen!, idTipo: idProduct! });
                            setLoadListOrigin(true);

                            break;
                        }
                    }
                    if (!foundOrigin){
                        history.push('/404/')
                    }
                } catch (error) {
                    const err = error as AxiosError
                    if (err.response) {
                        console.log(err.response.status)
                        console.log(err.response.data)
                        history.push('/404/')
                    }
                }

            };

            getOrigins();
        }
        getEntidades();


    }, []);

    const getEntidades = async () => {
        setLoadEntidades(false)
        const res = await contactoService.getEntidades();
        //res.status
        setLoadEntidades(true);
        // console.log(res.data);
        setEntidades(res.data)
        // establecer la primera entidad en el hook
        if (entidades.length>0){
            const auxContact : ContactModel = {...contact,entidadFederativa: '' }
            setContact(auxContact)
        }
    };

    const handleInputChange = (e: InputChange) =>{

        if (e.target.name == 'idTipo'){
            setProductName(e.target.value);
            searchProducts(e.target.value);

        }else {
            setContact({ ...contact, [e.target.name]: e.target.value });
        }
    }

    const searchProducts = async (productsName: string) => {
        if (productsName.length>1){
            const res = await contactoService.getProducts(productsName);
            console.log(res);
            const  listProducts: ProductsModel [] = res.data
            setProducts(listProducts)
            // si tenemos algo seleccionado
            let coincideNombre= false;
            if (contact.idTipo){
                for (let i  of listProducts){
                    if (i.descripcion== productsName){
                        coincideNombre= true;
                    }
                }
            }

            if (!coincideNombre){
                setContact({ ...contact, idTipo: 0 });
            }
        }
    }

    const selectProduct = (product: ProductsModel) => {
        setContact({ ...contact, idTipo: product.idTipo });
        setProductName(product.descripcion)

        let foundCoincidenece = false;
        for (let i of products){
            if (i.descripcion == productName){
                foundCoincidenece= true;
                break;
            }
        }
        if (!foundCoincidenece){
            setProducts([])
        }

    }

    const [showModalSucces, setShowModalSucces] = useState(false);

    const handleCloseModalSucces = () => setShowModalSucces(false);

    const handleShowModalSuccess = () => setShowModalSucces(true);

    const [sending, setSending] = useState(false);

    const [showErrorSubmit, setShowErrorSubmit] = useState<boolean>(false);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        if (validateFormContact()) {
            setSending(true);
            console.log('contacto2')

            try {
                const res =await contactoService.createNewContact(contact);
                const auxContact: ContactModel = contact;
                initialState.idContacto = auxContact.idContacto;
                initialState.idOrigen = auxContact.idOrigen;
                setContact(initialState);
                setProductName('')
                handleShowModalSuccess();
                setSending(false);
                const contactForm: ContactModel = res.data;
                createContactForm(contactForm.idContacto!);
            } catch (error) {
                const err = error as AxiosError
                setSending(false);
                setShowErrorSubmit(true);
                if (err.response) {
                    console.log(err.response.status)
                    console.log(err.response.data)
                }
            }



        }

    };
    // validaciones
    const [validName, setValidName] = useState<string>('');
    const [validEmpresa, setValidEmpresa] = useState<string>('');
    const [validCorreo, setValidCorreo] = useState<string>('');
    const [validProduct, setValidProduct] = useState<string>('');
    const [validTelefono, setValidTelefono] = useState<string>('');
    const [validEntidad, setValidEntidad] = useState<string>('');
    const [validMsg, setValidMsg] = useState<string>('');
    const [validCaptcha, setValidCaptcha] = useState<string>('');


    const validateFormContact = () : boolean => {
        setValidName('');
        setValidEmpresa('');
        setValidCorreo('');
        setValidTelefono('');
        setValidEntidad('');
        setValidProduct('');
        setValidMsg('');
        setValidCaptcha('');


        if (!contact.nombre){
            setValidName('Ingresa por favor un nombre.')
            return false;
        }

        if (!validaciones.validateEmail(contact.correo)){
            setValidCorreo('Ingresa por favor una dirección de correo válida.')
            return false;
        }
        if (!contact.telefono || contact.telefono.length< 10){
            setValidTelefono('Ingresa un teléfono válido, por favor.')
            return false;
        }
        if (!contact.entidadFederativa){
            setValidEntidad('Selecciona por favor una entidad.')
            return false;
        }
        if (!contact.idTipo){
            setValidProduct('Ingresa un producto válido, por favor.')
            return false;
        }

        if (!contact.mensaje){
            setValidMsg('Ingresa un Mensaje válido, por favor.')
            return false;
        }
        if (!captchaValid){
            setValidCaptcha('Haz clic en Yo no soy un Robot, por favor.')
            return false;
        }


        return true;
    };
    //captcha


    const onChange = (value: any ) =>{
        //console.log("Captcha value:", value);
        // @ts-ignore
        if (captcha.current.getValue()){
            // @ts-ignore
            console.log(captcha.current.getValue());
            setCaptchaValid(true);
        }

    };



    return (
        <>
            {loadListOrigin ? (
                <div id="contact-form">
                        <h1>{labelForm}</h1>
                        <h4>{subLabelFom}</h4>
                    {/*{ JSON.stringify(contact) }*/}
                        {showErrorSubmit ? (
                            <Alert variant="warning" onClose={() => setShowErrorSubmit(false)} dismissible>
                                <Alert.Heading className={"alert-heading"}>No se pudo enviar </Alert.Heading>
                                <p className={"msg-heading"}>
                                    Lo sentimos, algo ha ocurrido al enviar, intenta nuevamente.
                                </p>
                            </Alert>
                        ): null}
                        <div className="row">
                            <div className="col-12 politica-priv">
                                Los datos personales que aquí se recaban por Liberty Fianzas, S.A de C.V.
                                serán utilizados con la finalidad de ponernos en contacto con usted,
                                atenderle y aclarar cualquier duda respecto a las fianzas que ofrecemos.
                                <br/>
                                Si desea conocer nuestro aviso de privacidad, de clic <a href={PDF} target="_blank">aquí</a>.
                                <br/>
                            </div>

                        </div>
                        <form onSubmit={handleSubmit} className="form-row align-items-center">
                            <div className="col-sm-12 my-1">
                                <label htmlFor="nombre">Nombre:</label>
                                <input
                                    type="text"
                                    name="nombre"
                                    className={"form-control"+  (validName.length>0 ? ' is-invalid' : '')}
                                    id="nombre"
                                    autoFocus
                                    onChange={handleInputChange}
                                    value={contact.nombre}/>

                                {validName.length>0 ? (
                                    <div className="invalid-feedback">
                                        {validName}
                                    </div>
                                ): null}

                            </div>
                            <div className="col-sm-12 my-1">
                                <label htmlFor="empresa">Empresa:</label>
                                <span className="float-right text-muted">Opcional</span>
                                <input
                                    type="text"
                                    name="empresa"
                                    className={"form-control"+  (validEmpresa.length>0 ? ' is-invalid' : '')}
                                    id="empresa"
                                    onChange={handleInputChange}
                                    value={contact.empresa}/>

                                {validEmpresa.length>0 ? (
                                    <div className="invalid-feedback">
                                        {validEmpresa}
                                    </div>
                                ): null}
                            </div>
                            <div className="col-sm-6 my-1">
                                <label htmlFor="correo">Correo:</label>
                                <input
                                    type="email"
                                    name="correo"
                                    className={"form-control"+  (validCorreo.length>0 ? ' is-invalid' : '')}
                                    id="correo"
                                    onChange={handleInputChange}
                                    value={contact.correo}/>

                                {validCorreo.length>0 ? (
                                    <div className="invalid-feedback">
                                        {validCorreo}
                                    </div>
                                ): null}
                            </div>
                            <div className="col-sm-6 my-1">
                                <label htmlFor="telefono">Teléfono:</label>
                                <PhoneInput
                                    country={'mx'}
                                    value={contact.telefono}
                                    onChange={phone => { const a : ContactModel = {...contact,telefono: phone }; setContact(a) }}
                                />
                                {validTelefono.length>0 ? (
                                    <div className="invalid-feedback">
                                        {validTelefono}
                                    </div>
                                ): null}


                            </div>

                            <div className="col-sm-6 my-1">
                                <label htmlFor="entidadFederativa">Entidad Federativa:</label>
                                <select
                                    className="form-control"
                                    name="entidadFederativa"
                                    id="entidadFederativa"
                                    onChange={handleInputChange}
                                    value={contact.entidadFederativa}>

                                    <option value="">Selecciona una Entidad</option>
                                    {entidades.map((iEntidad) => (
                                        <option value={iEntidad.idEntidad} key={iEntidad.idEntidad}>{iEntidad.descripcion}</option>
                                    ))}

                                </select>

                                {validEntidad.length>0 ? (
                                    <div className="invalid-feedback">
                                        {validEntidad}
                                    </div>
                                ): null}
                            </div>
                            {
                                idProduct==0 ?(
                                    <div className="col-sm-6 my-1">
                                        <label htmlFor="products">Producto:</label>
                                        {validProduct.length>0 ? (
                                            <div className="invalid-feedback">
                                                {validProduct}
                                            </div>
                                        ): null}
                                        <input
                                            type="text"
                                            name="idTipo"
                                            className={"form-control"+  (validProduct.length>0 ? ' is-invalid' : '')}
                                            id="correo"
                                            autoComplete="off"
                                            onChange={handleInputChange}
                                            value={productName}/>
                                        {products.length > 0 ? (
                                            <Card className="list-products">
                                                <Card.Header>Selecciona un producto: </Card.Header>
                                                <ListGroup
                                                    variant="flush"
                                                >
                                                    {products.map((i) => (
                                                        <ListGroup.Item
                                                            key={i.idTipo}
                                                            onClick={() => {
                                                                selectProduct(i)
                                                            }}
                                                        >
                                                            {i.descripcion}
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            </Card>
                                        ):null
                                        }



                                    </div>
                                ): null
                            }


                            <div className="col-sm-12 my-1">
                                <label htmlFor="nombre">Mensaje:</label>
                                <textarea
                                    className="form-control"
                                    name="mensaje"
                                    id="mensaje"
                                    onChange={handleInputChange}
                                    value={contact.mensaje}
                                    rows={3}>
                        </textarea>

                                {validMsg.length>0 ? (
                                    <div className="invalid-feedback">
                                        {validMsg}
                                    </div>
                                ): null}
                            </div>

                            <div className="col-sm-12 my-1" id="recaptcha">

                                <ReCAPTCHA
                                    ref={captcha}
                                    sitekey="6LeV__cbAAAAADjQxYptRqGsjfCzZQy85G1-CnNf"
                                    onChange={onChange}
                                />

                                {validCaptcha.length>0 ? (
                                    <div className="invalid-feedback">
                                        {validCaptcha}
                                    </div>
                                ): null}


                            </div>

                            <div className="col-sm-12  mt-4 ">

                                {!sending ? (
                                        <button  className="btn btn-send">&nbsp;&nbsp;
                                            Enviar
                                            &nbsp;&nbsp;
                                        </button>

                                    ):
                                    <button className="btn btn-send" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        &nbsp;&nbsp;Enviando
                                    </button>
                                }


                            </div>


                        </form>

                        <Modal show={showModalSucces} onHide={handleCloseModalSucces}>
                            <Modal.Header closeButton>
                                <Modal.Title className="modal-title">Aviso</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="modal-text">
                                Su mensaje ha sido enviado, uno de nuestros ejecutivos en breve lo contactará.
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className={'btn btn-modal'} variant="primary" onClick={handleCloseModalSucces}>
                                    Aceptar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                ):
                <div className="col-md-8 offset-md-2 pt-4" id="contact-form">
                    <LoadingSpinner/>
                </div>
            }

        </>
    );
};


export enum LabelContact {
    contact = 'Contacto',
    quote = 'Solicitar Cotización'
}

