import React, { useCallback } from "react";
import { useDropzone, Accept, FileRejection } from "react-dropzone";

interface IDropzoneProps {
  onDrop?: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
  accept?: Accept;
  maxFiles?: number;
}

const Dropzone: React.FC<IDropzoneProps> = ({ onDrop, accept, maxFiles }) => {
  const handleDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      onDrop?.(acceptedFiles, fileRejections);
    },
    [onDrop]
  );
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop: handleDrop,
      accept,
      maxFiles,
    });

  // if (acceptedFiles.length)
  //   return (
  //     <div style={containerStyle}>
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         viewBox="0 0 24 24"
  //         style={{ width: 50 }}
  //       >
  //         <title>file-upload</title>
  //         <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M13.5,16V19H10.5V16H8L12,12L16,16H13.5M13,9V3.5L18.5,9H13Z" />
  //       </svg>
  //       <p style={{ marginTop: 2 }}>{acceptedFiles[0].name}</p>
  //     </div>
  //   );
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <div style={containerStyle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            style={{ width: 50 }}
          >
            <title>file-upload</title>
            <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M13.5,16V19H10.5V16H8L12,12L16,16H13.5M13,9V3.5L18.5,9H13Z" />
          </svg>
          <p style={{ marginTop: 2 }}>Suelte su archivo aquí ...</p>
        </div>
      ) : (
        <div style={containerStyle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            style={{ width: 50 }}
          >
            <title>file-upload-outline</title>
            <path d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,12L16,16H13.5V19H10.5V16H8L12,12Z" />
          </svg>
          <p style={{ marginTop: 2 }}>
            {acceptedFiles.length ? (
              acceptedFiles[0].name + " seleccionado"
            ) : (
              <>Arrastre su archivo aquí, o haga clic para seleccionar un archivo.</>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default Dropzone;

const containerStyle: React.CSSProperties = {
  border: "1px dashed grey",
  padding: 2,
  textAlign: "center",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: 150,
  width: "100%",
  justifyContent: "center",
};
