import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from "react";
import './LoadinSpinner.scss';
import {Spinner} from "react-bootstrap";
import logo from "../../assets/img/logo-web-liberty16.png";



export const LoadingSpinner = () => {

    return (
        <div className="loading-spinner">

            <img src={logo} className="img-fluid d-block mx-auto"/><br/>
            <Spinner animation="border"

                     variant="primary"
                     className="d-block mx-auto " />
        </div>

    );
};



