import axios from "axios";
import { IPolicy, IPolicyFormData, IProfile } from "./types";
import environment from "../../environments/environment";
import { getProfile } from "../../components/SerfLogin/utils";

axios.defaults.baseURL = environment.API_GENERAL;
export const getProfiles = async (): Promise<IProfile[]> => {
  const user = getProfile();
  const token = user.accessToken;
  const response = await axios.get("/politica/perfiles", {
    headers: {
      Authorization: token,
      "X-API-KEY": environment.API_GENERAL_KEY,
    },
  });
  return response.data;
};

export const savePolicy = async (policy: IPolicyFormData) => {
  const data = new FormData();
  const user = getProfile();
  const token = user.accessToken;
  data.append("file", policy.file!);
  data.append("titulo", policy.title);
  data.append("manifiesto", policy.manifest);
  data.append("idPerfil", policy.profile.toString());
  await axios.post("/politica", data, {
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data",
      "X-API-KEY": environment.API_GENERAL_KEY,
    },
  });
};

export const getPolicy = async (idPerfil: number): Promise<IPolicy> => {
  const user = getProfile();
  const token = user.accessToken;
  const response = await axios.get("/politica", {
    headers: {
      Authorization: token,
      "X-API-KEY": environment.API_GENERAL_KEY,
    },
    params: {
      idPerfil,
    },
  });
  return response.data;
};
