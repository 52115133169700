import { useEffect, useState } from "react";
import { IProfile } from "../views/UserPoliciesSubmission/types";
import { AxiosError } from "axios";
import { getProfiles } from "../views/UserPoliciesSubmission/utils";

export const usePoliciesProfiles = () => {
  const [profiles, setProfiles] = useState<IProfile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const data = await getProfiles();
        setProfiles(data);
      } catch (error) {
        const e = error as AxiosError;
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    init();
  }, []);
  return { profiles, isLoading, error };
};
